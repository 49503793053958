import React from "react"
import tw from "twin.macro"
import { Layout } from "components/Layout"
import styled from "styled-components"

const StyledPage = styled.div`
  ${tw`max-w-screen-md mx-auto`}
`

const Cookies = () => (
  <Layout>
    <StyledPage>
      <div className="inner">
        <h1>Cookies</h1>
        <script
          id="CookieDeclaration"
          src="https://consent.cookiebot.com/a72dad16-bd69-4b32-9b16-792a00deba69/cd.js"
          type="text/javascript"
          async
        />
      </div>
    </StyledPage>
  </Layout>
)

export default Cookies
